// src/components/Navbar.js
import React from 'react';
import styles from "./index.module.scss";

const Navbar = () => {  
  return (
    <div className={styles.aboutSection}>
      <span className={styles.title}>
        <h2 className={styles.font}>WHAT IS </h2>
        <img src="/static/sc.webp" />
      </span>
      <h2 className={styles.aboutText}>
        <span className={styles.strike}>$SC ISN'T </span>
        <span className={styles.highlight}>JUST A SHARK CAT,</span>
        <span className={styles.strike}> IT IS THE FUTURE OF DIGITAL</span>
        <span className={styles.strike}>CURRENCY. IT IS THE PERFECT BALANCE OF BLOCKCHAIN AND</span>
        <span className={styles.strike}>OUR FELINE FRIENDS THAT WILL CHANGE THE WORLD OF</span>
        <span className={styles.strike}>FINANCE FOREVER.</span>
      </h2>
      <p><img className={styles.cat} src="/static/nala.jpeg" /></p>
    </div>
  );
};

export default Navbar;
