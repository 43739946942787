// src/components/Navbar.js
import React from 'react';
import ImageGallery from "react-image-gallery";
import styles from "./index.module.scss";

const images = [];

for (let i=0; i<17; i++) {
    const path = `/static/slideshow/${i + 1}.jpeg`;
    images.push({
        original: path,
        thumbnail: path
    })
}

const Segue = () => {
  return (
    <div className={styles.gallery}>
        <ImageGallery autoPlay={true} items={images} />
    </div>
  );
};

export default Segue;
