// src/App.js
import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import AboutSection from "./components/About";
import Gallery from "./components/Gallery";
import styled from 'styled-components';
import Segue from './components/Segue';
import Footer from "./components/Footer";
import Bubbles from "./components/Bubbles";

const AppWrapper = styled.div`
  background-color: #429cb5;
  height: 100%;
  padding-top: 150px;
  width: 100vw;

  @media (max-width: 1400px) {
    padding-top: 200px;
  }

  h2 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    padding: 20px;
    text-align: center;
  }
`;

window.Jupiter.init({
  displayMode: "widget",
  endpoint: "https://dry-spring-borough.solana-mainnet.quiknode.pro/a9adeb21bb846d0efecee78bfb0375ce51115c76/",
  formProps: {
    fixedOutputMint: true,
    initialInputMint: "So11111111111111111111111111111111111111112",
    initialOutputMint: "6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui",
  },
});

function App() {
  return (
    <>
      <Navbar />
      <AppWrapper id="homeHeader">
        <AboutSection />
        <Segue />
        <Gallery />
        <Footer />
      </AppWrapper>
      <Bubbles />
      <div id="integrated-terminal"></div>
    </>
  );
}

export default App;
