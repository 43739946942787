// src/components/Navbar.js
import React from 'react';
import styled from 'styled-components';

const SocialsWrapper = styled.p`
  font-family: Water, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  width: 300px;
  color: #fff;
  font-size: 1rem;
  padding: 10px;
  margin: 1em 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
`;

const SocialLink = styled.a`
font-family: Water, sans-serif;
letter-spacing: 2px;
text-align: center;
width: 300px;
color: #fff;
text-decoration: none;
padding: 0 20px;
`;

const SocialImage = styled.img`

`;

const Navbar = () => {
  return (
    <SocialsWrapper>
      <SocialLink href="https://www.dextools.io/app/en/solana/pair-explorer/BSzedbEvWRqVksaF558epPWCM16avEpyhm2HgSq9WZyy?t=1711734019696" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/dextools.webp" />
      </SocialLink>
      <SocialLink href="https://twitter.com/SharkCatSolana" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/twitter.webp" />
      </SocialLink>
      <SocialLink href="https://t.me/sharkcatonsolana" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/telegram.webp" />
      </SocialLink>
      <SocialLink href="/static/paper.pdf" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/paper.png" />
      </SocialLink>
    </SocialsWrapper>
  );
};

export default Navbar;
