import React from "react";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import fin from "./fin";
import { colors } from '../../theme';

const MarqueeText = styled.p`
    font-weight: 700;
    margin: 2px auto 0;
    text-align: right;
    color: #fff;
    font-size: 1rem;
    padding: 10px;
    width: 100%;
`;

const FinWrapper = styled.span`
    margin: 0;
    padding-left: 50px;
`;

const Fin = styled.img`
    color: #fff;
    margin-top: 1px !important;
`;

const App = () => (
  <Marquee gradientColor={colors.bg1} gradient={true} speed={100}>
    <MarqueeText>It’s a shark , it’s a cat , it’s a shark cat</MarqueeText>
    <FinWrapper>
        <Fin width="25" src={fin}></Fin>
    </FinWrapper>
    <MarqueeText>It’s a shark , it’s a cat , it’s a shark cat</MarqueeText>
    <FinWrapper>
        <Fin width="25" src={fin}></Fin>
    </FinWrapper>
    <MarqueeText>It’s a shark , it’s a cat , it’s a shark cat</MarqueeText>
    <FinWrapper>
        <Fin width="25" src={fin}></Fin>
    </FinWrapper>
    <MarqueeText>It’s a shark , it’s a cat , it’s a shark cat</MarqueeText>
    <FinWrapper>
        <Fin width="25" src={fin}></Fin>
    </FinWrapper>
  </Marquee>
);

export default App;
