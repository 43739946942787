// src/components/Navbar.js
import React from 'react';
import Socials from "../Socials";
import styles from "./index.module.scss";

const Segue = () => {
  return (
    <span className={styles.community}>
        <Socials />
    </span>
  );
};

export default Segue;
