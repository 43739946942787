// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NavbarMarquee from "./marquee";
import Socials from "../Socials";

const HeaderWrapper = styled.div`
background-color: #429cb5;
filter: drop-shadow(-5px 10px 10px #666666);
left: 0;
position: fixed;
top: 0;
width: 100vw;
z-index: 99 !important;
display: block;
`;

const NavInner = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  /* Breakpoint <= 1400px */
  @media (max-width: 1400px) {
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }
`;

const NavLink = styled.a`
  font-family: Water, sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  width: 300px;
  color: #fff;
  text-decoration: none;

  /* Breakpoint <= 1400px */
  @media (max-width: 1400px) {
    font-size: .7rem;
  }
`;

const MobileSpan = styled.span`
p {
  margin: 10px auto;
  padding: 0;
  text-align: center;
}

img {
    width: 20px !important;
}
`;

const Navbar = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width state on resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Setup window resize event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeaderWrapper>
      <NavbarMarquee></NavbarMarquee>
        <NavInner>
          <NavLink href="/">SHARKCAT</NavLink>
          <NavLink href="https://jup.ag/swap/SOL-SC_6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui" target="_blank" rel="noopener noreferrer">BUY</NavLink>
          <NavLink href="https://dexscreener.com/solana/bszedbevwrqvksaf558eppwcm16avepyhm2hgsq9wzyy" target="_blank" rel="noopener noreferrer">CHART</NavLink>
          <NavLink href="https://sharkcatsolana.store" target="_blank" rel="noopener noreferrer">MERCH STORE</NavLink>
          {
            windowWidth >= 1060 ? (
              <Socials />
            ) : null
          }
        </NavInner>
        {
            windowWidth < 1060 ? (
              <MobileSpan>
                <Socials />
              </MobileSpan>
            ) : null
          }
    </HeaderWrapper>
  );
};

export default Navbar;
