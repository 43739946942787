import React, { useState, useEffect, useRef } from 'react';
import './Bubbles.css';

let running = false;

function Bubbles() {
  const [bubbles, setBubbles] = useState([]);
  const bubbleCounter = useRef(0);

  // Create a bubble with specific styles and an id
  const createBubble = () => {
    const id = bubbleCounter.current++;
    const size = Math.floor(Math.random() * 51) + 20; // Random size between 20px and 70px
    const left = Math.floor(Math.random() * window.innerWidth);

    // Schedule removal after a random delay between 3.5s and 4.5s
    const delay = Math.random() * 2000 + 3000; // Random delay between 3500ms and 4500ms
    const removeId = id;
    setTimeout(() => {
      setBubbles((prev) => prev.filter((bubble) => bubble.key !== removeId));
      console.log(document.getElementById('theID'));
      document.getElementById(removeId).remove();

    }, delay);

    return (
      <span
        id={id}
        key={id}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          left: `${left}px`,
        }}
        className="bubble"
      ></span>
    );
  };

  // Generate a batch of bubbles
  const generateBubbles = () => {
    if (running === true) return;
    running = true;
    setTimeout(() => {
      running = false;
    }, 5000);
    const newBubbles = [];
    const count = 20; // Adjust the number of bubbles created on each event
    for (let i = 0; i < count; i++) {
      newBubbles.push(createBubble());
    }
    setBubbles((prev) => [...prev, ...newBubbles]);
  };

  useEffect(() => {
    // Generate bubbles only on scroll and window resize
    const handleEvents = () => {
      console.log("Handling events")
      generateBubbles();
    };
    window.addEventListener('scroll', handleEvents);
    window.addEventListener('resize', handleEvents);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleEvents);
      window.removeEventListener('resize', handleEvents);
    };
  }, []);

  return <section>{bubbles}</section>;
}

export default Bubbles;
